<template>
    <router-view/>
</template>

<script>

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';



export default {
    name: 'App'
}

</script>

<style>
  html,body {
    overflow-x: hidden !important;
    position: relative;
  }
</style>

